import {useLocation} from '@remix-run/react';
import {useEffect, useRef, useState} from 'react';
import {form} from '~/services/forms';
import {getEncodedObject} from '~/services/forms/getRequestData';
import {clientSideCookie} from './cookie-client';
import {useInterval} from './useInterval';

type FormEntries = {
  [k: string]: FormDataEntryValue;
};

export const useAutoSaveForm = (route: string) => {
  const ref = useRef<HTMLFormElement>(null);
  const initialValuesRef = useRef<FormEntries>();

  const formDataRef = useRef('');

  useInterval(() => {
    if (!ref.current) return;

    const formData = new FormData(ref.current);

    const data = Object.fromEntries(formData.entries());
    formDataRef.current = JSON.stringify(data);
    if (!initialValuesRef.current) {
      initialValuesRef.current = data;
      return;
    }

    if (JSON.stringify(initialValuesRef.current) !== JSON.stringify(data)) {
      const parsedData = form.getDataFromEntries(data as Record<string, string>);
      clientSideCookie.set('waitingForm', JSON.stringify({data: getEncodedObject(parsedData), route}), 1);
      initialValuesRef.current = data;
    }
  }, 300);

  return ref;
};

export const useAutResetState = <V>(defaultValue: V) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return [value, setValue] as const;
};

export const useClearFormOnPathChange = () => {
  const {pathname} = useLocation();
  useEffect(() => {
    clientSideCookie.remove('waitingForm');
  }, [pathname]);
};
